window.VideoTestimonialSlider = (randomId) => {
	return {
		swiper: null,
        init() {
            setTimeout(() => {
                this.swiper = new Swiper('.swiper-container' + randomId, {
                    preloadImages: false,
                    lazy: true,
                    loop: false,
                    spaceBetween: 20,
                    slidesPerView: 1,
                    initialSlide: 1,
                    speed: 400,
                    navigation: {
                        nextEl: this.$refs.swiperNext,
                        prevEl: this.$refs.swiperPrev,
                    },
                    a11y: true
                });
            }, 0);
        },
	};
};
